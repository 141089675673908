import * as React from 'react'
import { map } from 'lodash'
import tw, { styled } from 'twin.macro'
import { useAnimation, motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { SHIPPING_INFO_F2024 } from '../../utils/constants'
import { buttonVariants, revealVariants } from '../../utils/motion'
import markLeft from '../../assets/images/landing-page-20-10/2023/lettersLeft.png'
import markRight from '../../assets/images/landing-page-20-10/2023/lettersRight.png'

const cards = [
  {
    content: 'Bánh tươi trong ngày',
    imageLink: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heartFea1.png"
        alt="Bánh tươi trong ngày"
      />
    ),
  },
  {
    content: 'Mua 1 tặng 1',
    imageLink: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heartFea2.png"
        alt="Mua 1 tặng 1"
      />
    ),
  },
  {
    content: `Đồng giá ship ${
      SHIPPING_INFO_F2024.SHIPPING_FEE_20T10 / 1000
    }.000đ /bánh`,
    imageLink: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heartFea3.png"
        alt={`Đồng giá ship ${
          SHIPPING_INFO_F2024.SHIPPING_FEE_20T10 / 1000
        }.000đ/bánh`}
      />
    ),
  },
  {
    content: `Có xuất hóa đơn`,
    imageLink: (
      <StaticImage
        src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heartFea4.png"
        alt="Có xuất hóa đơn"
      />
    ),
    subContent: '(giá chưa gồm VAT)',
  },
]
export default function FeatureSection() {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])
  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <FeatureContainer id="gioithieu" tw="relative mb-6">
        <div tw="w-full absolute left-0 top-0">
          <StaticImage
            src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/cloudFea.png"
            alt="ảnh mây"
            layout="fullWidth"
          />
        </div>
        <div
          tw="lg:container mx-auto
          xl:max-w-screen-xl!"
        >
          <div tw="relative z-20">
            <FeatureTitle>Tại sao bạn nên lựa chọn Savor Cake</FeatureTitle>
            <FeatureDesc>
              Hãy cùng tìm hiểu những điểm nổi bật của Savor Cake nhé!
            </FeatureDesc>
          </div>
        </div>
        <CardsBackground>
          <div
            tw="lg:container
            lg:max-w-screen-lg!
            mx-auto px-2 xs:px-4 sm:px-6 md:px-8 lg:px-16 xl:px-0
            flex justify-center items-center gap-2
            z-10"
          >
            {map(cards, (card) => (
              <motion.div
                key={card.content}
                variants={buttonVariants}
                whileHover="hover"
                tw="w-1/4"
              >
                <BaseFeatureCard>
                  <div>{card.imageLink}</div>
                  <div
                    tw="text-center font-bold uppercase
                    text-xs sm:text-sm md:text-lg lg:text-xl
                    px-1 lg:px-3 xl:px-0
                    lg:pt-4 xl:pt-0
                    font-savor-cake-secondary-quicksand
                    h-9 w-full!"
                  >
                    {card.content}
                    {card.subContent ? (
                      <p tw="font-medium text-xs sm:text-sm md:text-base">
                        {card.subContent}
                      </p>
                    ) : null}
                  </div>
                </BaseFeatureCard>
              </motion.div>
            ))}
          </div>
        </CardsBackground>
      </FeatureContainer>
    </motion.div>
  )
}

const FeatureContainer = tw.div`
  pt-11 pb-20 md:pb-14
  px-5 md:px-12
`
const FeatureTitle = tw.h2`
  text-center text-4xl md:text-5xl
  text-8t3-pink-500
  font-savor-cake-primary-lexend font-bold
  py-3
  md:px-20 xl:px-0 xl:pb-5
  `
const FeatureDesc = tw.p`
  py-6
  text-center text-base md:text-2xl
  text-20t10-red-1000 font-medium
  font-savor-cake-secondary-quicksand
`

const CardsBackground = styled.div`
  position: relative;
  width: auto;
  height: 9rem;
  background-image: linear-gradient(
    180deg,
    #ffd8df 0%,
    rgba(255, 216, 223, 0) 95.78%
  ) !important;
  margin: auto;
  border-radius: 20px;
  margin-top: 2rem;
  margin-bottom: 1rem;
  @media (min-width: 425px) {
    margin-top: 3rem;
  }
  @media (min-width: 640px) {
    margin-top: 4rem;
    height: 10rem;
  }
  @media (min-width: 768px) {
    margin-top: 6rem;
    margin-bottom: 3rem;
  }
  @media (min-width: 1024px) {
    margin-top: 8rem;
    margin-bottom: 4rem;
    height: 12rem;
  }
  @media (min-width: 1280px) {
    margin-top: 8rem;
    margin-bottom: 5rem;
    height: 18rem;
    max-width: 1280px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 6rem;
    height: 6rem;
    top: -4rem;
    right: -1.5rem;
    overflow-x: hidden;
    @media (min-width: 640px) {
      width: 10rem;
      height: 10rem;
      top: -6rem;
      right: -3rem;
    }
    @media (min-width: 768px) {
      width: 12rem;
      height: 12rem;
      top: -8rem;
      right: -4rem;
    }
    @media (min-width: 1024px) {
      width: 14rem;
      height: 14rem;
      right: -5rem;
      top: -10rem;
    }
    @media (min-width: 1280px) {
      width: 18rem;
      height: 18rem;
      top: -12rem;
      right: -6rem;
    }
    background: url(${markRight});
    background-size: cover;
    background-position: center;
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    width: 5rem;
    height: 5rem;
    bottom: -6rem;
    left: -1.5rem;
    overflow-x: hidden;
    @media (min-width: 640px) {
      width: 8rem;
      height: 8rem;
      bottom: -8rem;
      left: -3rem;
    }
    @media (min-width: 768px) {
      width: 10rem;
      height: 10rem;
      bottom: -9rem;
      left: -4rem;
    }
    @media (min-width: 1024px) {
      width: 12rem;
      height: 12rem;
      bottom: -14rem;
    }
    @media (min-width: 1280px) {
      width: 16rem;
      height: 16rem;
      left: -8rem;
    }
    background: url(${markLeft});
    background-size: cover;
    background-position: center;
    z-index: 1;
  }
`

const BaseFeatureCard = tw.div`
  relative
  flex flex-col justify-center items-center
  gap-y-4 md:gap-y-0 xl:gap-y-10
  md:col-span-1
  md:row-span-3 z-20
  rounded-xl
  pt-6 sm:pt-7 lg:pt-10
  pb-12 xs:pb-14 sm:pb-16 md:pb-20 lg:pb-24
  xs:px-3 sm:px-4 md:px-6 lg:px-0
`
