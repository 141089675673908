import { styled } from 'twin.macro'
import { map } from 'lodash'
import * as React from 'react'
import { useAnimation, motion } from 'framer-motion'
import { useEffect } from 'react'
import { revealVariants } from '../../utils/motion'
import { useInView } from 'react-intersection-observer'
import background from '../../assets/images/landing-page-20-10/2023/policyBg.png'
import headingBg from '../../assets/images/landing-page-20-10/2023/policyTitle.png'
import tw from 'twin.macro'
import { SHIPPING_INFO_F2024 } from '../../utils/constants'
import { StaticImage } from 'gatsby-plugin-image'

const policyItems = [
  {
    title: 'Mẫu bánh & Quà tặng',
    description:
      'Bánh giao trong 2 ngày 19 & 20.10 chỉ nhận 1 trong 6 mẫu 20.10 và cố định size.',
    highlight1a: '• Mẫu 2 bó hoa Tulip hồng/tím tặng kèm',
    strong1: ' hộp mica trong suốt ',
    highlight1b: 'sang xịn mịn.',
    highlight2a: '• Bánh giao trong 2 ngày 19 & 20.10 được tặng kèm',
    strong2: ' thiệp xinh Best wish for you',
    highlight2b: '. Bánh giao từ 18.10 trở về trước không được tặng kèm thiệp.',
  },
  {
    title: 'Phí ship',
    description: `Đồng giá ship ${
      SHIPPING_INFO_F2024.SHIPPING_FEE_20T10 / 1000
    }k/bánh áp dụng với tất cả bánh giao trong 2 ngày 19 & 20.10 (kể cả đặt trước và đơn > ${
      SHIPPING_INFO_F2024.FREE_SHIP_VALID_NORMAL_F2024 / 1000
    }k).`,
  },
  {
    title: 'Hình thức thanh toán',
    description:
      'Thanh toán chuyển khoản 100% đối với bánh giao trong 2 ngày 19 & 20.10.',
  },
  {
    title: 'Lưu ý đặt hàng',
    description:
      '• Ngày 19 & 20.10, khách muốn đặt hàng giao tại cơ sở, có thể xem danh sách cơ sở sẵn bánh ở dưới đây và liên hệ để có thể tự chọn bánh có sẵn.',
    // highlight1a:
    //   '• Khách qua cơ sở lấy bánh có sẵn luôn và ko đặt trước thì ko cần phải chuyển khoản trước và miễn phí ship.',
    highlight2a:
      '• Khách hàng có thể mua bánh có sẵn tại cửa hàng hoặc đặt qua fanpage tối thiểu trước 2-3 tiếng.',
  },
]

const AbsoluteStyle = {
  position: 'absolute',
  minWidth: '100px',
  minHeight: '100px',
  height: 'auto',
  zIndex: 0,
  overflow: 'visible',
  aspectRatio: 'auto',
}

// const HeartRight = () => {
//   return (
//     <StaticImage
//       src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heartRight.png"
//       alt="ảnh trái tim"
//       placeholder="blurred"
//       style={{ ...AbsoluteStyle, width: '15%', top: 0, right: 0 }}
//     />
//   )
// }
// const HeartLeft = () => {
//   return (
//     <StaticImage
//       src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/heartLeft.png"
//       alt="ảnh trái tim"
//       placeholder="blurred"
//       style={{ ...AbsoluteStyle, width: '15%', bottom: 0, left: 0 }}
//     />
//   )
// }
// const FlyFlower = () => {
//   return (
//     <StaticImage
//       src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/flyFlower.png"
//       alt="ảnh hoa bay"
//       placeholder="blurred"
//       style={{
//         ...AbsoluteStyle,
//       }}
//     />
//   )
// }

const FlowersBot = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/flowers.png"
      alt="ảnh hoa bay"
      placeholder="blurred"
      style={{
        ...AbsoluteStyle,
      }}
    />
  )
}
const TulipCurve = () => {
  return (
    <StaticImage
      src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/tulipCurve.png"
      alt="ảnh hoa"
      placeholder="blurred"
      style={{
        ...AbsoluteStyle,
        width: '18%',
        right: 0,
        bottom: 0,
      }}
    />
  )
}
const bouquetImages = [
  <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/giftCard1.png" />,
  <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/giftCard2.png" />,
  <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/giftCard3.png" />,
]

export default function PolicySection() {
  const controls = useAnimation()
  const [ref, inView] = useInView()
  useEffect(() => {
    if (inView) {
      controls.start('visible')
    }
  }, [controls, inView])

  return (
    <motion.div
      ref={ref}
      variants={revealVariants}
      initial="hidden"
      animate={controls}
    >
      <PolicySectionWrapper tw="relative z-10">
        {/* hoa phụ kiện */}
        <div tw="relative">
          <div
            id="bo-hoa-35k"
            tw="lg:container lg:max-w-screen-lg! mx-auto
            flex flex-col gap-4 justify-center items-center
            px-4 xl:px-0
            py-6 lg:py-10 xl:py-16"
          >
            {/* title */}
            <div tw="px-6 md:px-20 lg:px-32">
              <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/giftTitle.png" />
            </div>
            {/* images */}
            <div
              tw="flex lg:gap-2 justify-center items-center
              px-2 xl:px-0"
            >
              {bouquetImages}
            </div>
            {/* price */}
            <div tw="w-36 sm:w-44 md:w-56 lg:w-68">
              <StaticImage src="https://s3.kstorage.vn/savor-web/src/assets/images/landing-page-20-10/2023/giftPrice.png" />
            </div>
          </div>
        </div>
        {/* Main policy */}
        <div tw="relative">
          <FlowersBot />
          <TulipCurve />
          <div
            id="policy"
            tw="py-3 lg:py-6 2xl:py-8
          lg:max-w-screen-lg!
          mx-auto lg:container"
          >
            <HeadingSection
              tw="my-4 py-4
            mx-8 xs:mx-12 sm:mx-20 md:mx-32 lg:mx-36
            "
            >
              <h2
                tw="font-savor-cake-primary-lexend text-white text-center
              text-base sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl
              uppercase
              "
              >
                chính sách ship & thanh toán
              </h2>
            </HeadingSection>

            <div
              tw="
            bg-white w-9/10 h-full
            py-4
            shadow-md
            mx-auto relative
            xs:(after:content-[''] after:absolute
            after:w-[98%] after:h-[90%] after:-z-1
            after:bg-savor-cake-pink-400 after:rotate-[2.5deg]
            after:right-0 after:top-14 after:left-3) md:after:rotate-[7deg]
            "
            >
              <div tw="w-11/12 m-auto border-2 border-savor-cake-pink-300 p-4 pt-8 box-border">
                <div tw="lg:container md:max-w-screen-md! mx-auto">
                  {map(policyItems, (item, index) => {
                    return <PolicyItem item={item} index={index} key={index} />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </PolicySectionWrapper>
    </motion.div>
  )
}

const PolicyItem = ({ item, index }) => {
  return (
    <div
      tw="flex pb-3
      "
    >
      <div tw="justify-self-start">
        <div
          tw="bg-linear-gradient-pink-100
          rounded-full flex items-center justify-center
          w-10 h-10 sm:w-12 sm:h-12 md:w-14 md:h-14
          "
        >
          <div
            tw="font-savor-cake-primary-lexend font-bold text-white
            text-2xl sm:text-3xl md:text-4xl
            "
          >
            {index + 1}
          </div>
        </div>
      </div>

      {/* For the description */}
      <div tw="pl-3">
        <h2
          tw="p-0 m-0 text-savor-cake-pink-400 font-savor-cake-primary-lexend
          text-xl sm:text-2xl md:text-3xl lg:text-4xl
          uppercase
          "
        >
          {item.title}
        </h2>
        <p
          tw="p-0 font-savor-cake-secondary-quicksand font-semibold
          text-savor-cake-pink-600
          text-sm md:text-base lg:text-xl
          "
        >
          {item.description} <br />
          <SpanGift>{item.highlight1a}</SpanGift>
          <strong tw="text-savor-cake-pink-400 font-bold">
            {item.strong1}
          </strong>
          <SpanGift>{item.highlight1b}</SpanGift>
          <br />
          <SpanGift>{item.highlight2a}</SpanGift>
          <strong tw="text-savor-cake-pink-400 font-bold">
            {item.strong2}
          </strong>
          <SpanGift>{item.highlight2b}</SpanGift>
        </p>
      </div>
    </div>
  )
}

const SpanGift = tw.span`font-semibold mt-10`

const PolicySectionWrapper = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`

const HeadingSection = styled.div`
  background-image: url(${headingBg});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
`
